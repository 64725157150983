import {
  MultiSelect as MantineMultiSelect,
  MultiSelectProps as MantineMultiSelectProps,
} from '@mantine/core';
import { IconSelect } from '@tabler/icons-react';
import { useController, useFormContext } from 'react-hook-form';

type MultiSelectProps = {
  name: string;
  label: string;
  data: MantineMultiSelectProps['data'];
  defaultValue?: MantineMultiSelectProps['defaultValue'];
  placeholder?: string;
};

export const MultiSelect = ({
  name,
  label,
  data,
  defaultValue,
  placeholder,
}: MultiSelectProps) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ control, name });

  return (
    <MantineMultiSelect
      value={value}
      onChange={onChange}
      error={error?.message}
      label={label}
      placeholder={placeholder ? placeholder : label}
      data={data}
      styles={{ label: { fontWeight: 900 } }}
      checkIconPosition="right"
      defaultValue={defaultValue}
      rightSection={
        <IconSelect
          size={18}
          {...(!error && { color: 'var(--mantine-primary-color-filled)' })}
        />
      }
    />
  );
};
