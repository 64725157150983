import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import minMax from 'dayjs/plugin/minMax';

dayjs.extend(utc);
dayjs.extend(minMax);

export const DATE_YEAR_MONTH_DAY_FORMAT = 'YYYY-MM-DD';
export const DATE_MONTH_NAME_FORMAT_SHORT = 'MMM';
export const DATE_MONTH_NAME_YEAR_FORMAT = 'MMMM YYYY';

const dayjsExt = dayjs;
const minDate = dayjsExt.min;
const maxDate = dayjsExt.max;
const dayjsExtUtc = dayjsExt.utc;

const getDaysInYear = (): number => {
  const today = dayjsExtUtc();
  const startOfYear = today.startOf('year');
  const endOfYear = today.endOf('year');
  const daysInYear = endOfYear.diff(startOfYear, 'days');
  return daysInYear;
};

export { minDate, maxDate, dayjsExtUtc, getDaysInYear };
