var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"1e27cf6150ad6633a4060e08124f91aafc2786d5"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import { NEXT_IS_PRODUCTION_ENV, NEXT_ENV } from '@edt-monorepo/shared/utils';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: 'https://a6df0746da797de37de378520f715875@o4507003408678912.ingest.us.sentry.io/4507003410579456',
  integrations: [Sentry.replayIntegration()],
  tracesSampleRate: NEXT_IS_PRODUCTION_ENV ? 1.0 : 0.0,
  replaysSessionSampleRate: NEXT_IS_PRODUCTION_ENV ? 0.1 : 0.0,
  replaysOnErrorSampleRate: NEXT_IS_PRODUCTION_ENV ? 1.0 : 0.0,
  environment: NEXT_ENV,
  enabled: NEXT_IS_PRODUCTION_ENV,
});
