import { DataTable, DataTableColumn } from 'mantine-datatable';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { Hint } from '../Hint';
import { ActionIcon } from '@mantine/core';
import { IconMinus, IconPlus } from '@tabler/icons-react';
import { ExpandedTable } from './ExpandedTable';
import { equals, reject } from 'ramda';

export type RowStructure = { id: string; expandable?: { label: string } };

export type ExpandableDataTableProps<T> = {
  columns: DataTableColumn<T>[];
  records: { row: T; subRows?: T[] }[];
  emptyState?: ReactNode;
  allowMultipleExpanded?: boolean;
  allowToggleAll?: boolean;
};

export function ExpandableDataTable<T extends RowStructure>({
  columns: passedInColumns,
  records,
  emptyState,
  allowMultipleExpanded,
  allowToggleAll,
}: ExpandableDataTableProps<T>) {
  const [expandedRows, setExpandedRows] = useState<string[]>([]);

  const areAllRowsExpanded = useCallback(
    () => expandedRows.length === Object.values(records).length,
    [expandedRows.length, records]
  );

  const toggleAllRows = useCallback(() => {
    if (areAllRowsExpanded()) {
      setExpandedRows([]);
    } else {
      setExpandedRows(Object.values(records).map(({ row: { id } }) => id));
    }
  }, [areAllRowsExpanded, records]);

  const isRowExpanded = useCallback(
    (rowKey: string) => expandedRows.includes(rowKey),
    [expandedRows]
  );

  const toggleRowExpansion = useCallback(
    (rowKey: string) => {
      if (allowMultipleExpanded) {
        isRowExpanded(rowKey)
          ? setExpandedRows((vals) => reject(equals(rowKey), vals))
          : setExpandedRows((vals) => [...vals, rowKey]);
      } else {
        isRowExpanded(rowKey) ? setExpandedRows([]) : setExpandedRows([rowKey]);
      }
    },
    [isRowExpanded, allowMultipleExpanded]
  );

  const columns: DataTableColumn<T>[] = useMemo(
    () => [
      {
        accessor: 'toggle',
        width: 35,
        textAlign: 'center',
        cellsStyle: () => ({
          padding: 0,
          backgroundColor: 'var(--mantine-color-body)',
        }),
        titleStyle: () => ({
          padding: 0,
          backgroundColor: 'var(--mantine-color-body)',
        }),
        title: (
          <div>
            {allowToggleAll && allowMultipleExpanded ? (
              <Hint label={areAllRowsExpanded() ? 'Hide all' : 'Show all'}>
                <ActionIcon
                  size="sm"
                  variant="outline"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleAllRows();
                  }}
                >
                  {areAllRowsExpanded() ? (
                    <IconMinus size={16} />
                  ) : (
                    <IconPlus size={16} />
                  )}
                </ActionIcon>
              </Hint>
            ) : null}
          </div>
        ),
        render: ({ id, expandable }) => {
          if (!expandable) {
            return null;
          }
          return (
            <Hint
              label={
                isRowExpanded(id)
                  ? `Hide ${expandable.label}`
                  : `Show ${expandable.label}`
              }
            >
              <ActionIcon
                size="sm"
                variant="light"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleRowExpansion(id);
                }}
              >
                {isRowExpanded(id) ? (
                  <IconMinus size={16} />
                ) : (
                  <IconPlus size={16} />
                )}
              </ActionIcon>
            </Hint>
          );
        },
      },
      ...passedInColumns,
    ],
    [
      areAllRowsExpanded,
      toggleAllRows,
      isRowExpanded,
      toggleRowExpansion,
      passedInColumns,
      allowMultipleExpanded,
      allowToggleAll,
    ]
  );

  return (
    <DataTable
      idAccessor="id"
      columns={columns}
      records={records.map((r) => r.row)}
      withColumnBorders
      withTableBorder
      backgroundColor="var(--mantine-color-body)"
      emptyState={emptyState}
      {...(emptyState &&
        !records.length && {
          minHeight: 100,
        })}
      defaultColumnProps={{
        width: 100,
        textAlign: 'center',
        ellipsis: true,
      }}
      rowExpansion={{
        trigger: 'never',
        expanded: {
          recordIds: expandedRows,
          onRecordIdsChange: setExpandedRows,
        },
        content: ({ record: { id } }) => (
          <ExpandedTable
            columns={passedInColumns}
            records={records.find((r) => r.row.id === id)?.subRows || []}
          />
        ),
      }}
    />
  );
}
